@font-face {
  font-family: 'Nunito-SemiBold';
  src: local('Nunito-SemiBold'),
    url(./helpers/font/Nunito-SemiBold.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'), url(./helpers/font/Nunito-Bold.ttf) format('woff');
}

@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'),
    url(./helpers/font/Nunito-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url(././helpers/font/Roboto-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(././helpers/font/Roboto-Medium.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url(././helpers/font/Roboto-Bold.ttf) format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url(././helpers/font/Roboto-Medium.ttf) format('woff');
}

body {
  margin: 0;
  font-family: Nunito-SemiBold !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f2f2;
}

code {
  font-family: Nunito-SemiBold !important;
}

.snackbarcustomized {
  min-width: max-content !important;
  padding-left: 10px !important;
}

.invitationFocus {
  background: white !important;
  border: inset !important;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Nunito-SemiBold !important;
}

.heading.MuiTypography-h6 {
  font-weight: bold !important;
}

.choosefile::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: #1cb6e0;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}
#roster-head thead tr th:nth-child(6) {
  text-align: center;
  padding-left: 30px;
}
.Mui-focusVisible .MuiTouchRipple-root {
  display: none;
}
#google_translate_element2 .goog-te-gadget-simple img,
#google_translate_element2 .goog-te-gadget-simple span {
  display: none;
}

#google_translate_element2 .goog-te-gadget-simple img:first-child,
#google_translate_element2 .goog-te-gadget-simple span:nth-of-type(1) {
  display: inline-block;
}

.hovertablecell:hover {
  background: red;
  background-color: red;
}
.track,
.filled {
  stroke-width: 10;
  fill: none;
}

.track {
  stroke: #eee;
}

.filled {
  stroke: blue;
}

.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.circle-bg {
  fill: none;
  stroke: #0058ff;
  stroke-width: 3.2;
}

.circle {
  fill: none;
  stroke-width: 3.2;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #21d59b;
}

.percentage {
  fill: black;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
.g-recaptcha {
  width: 100% !important;
}
.signin-repcatcha > div > div > div > iframe {
  background-color: red !important;
  transform: scale(1);
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  /* -52px -102px */
}
.signin-repcatcha > div > div > div > iframe > #document > html > body {
  background-color: yellow !important;
}
.g-recaptcha-wrapper {
  position: relative;
  border: 1px solid #ededed;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 0;
}
.g-recaptcha-wrapper #topHider {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px !important;
  width: 100%;
  background-color: #f9f9f9;
}
.g-recaptcha-wrapper #rightHider {
  position: absolute;
  top: 0;
  left: 375px;
  height: 100% !important;
  width: 15px;
  background-color: #f9f9f9;
}
.g-recaptcha-wrapper #leftHider {
  position: absolute;
  top: 0;
  left: 79px;
  height: 100% !important;
  width: 2px;
  background-color: #f9f9f9;
}
.g-recaptcha-wrapper #bottomHider {
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 2px !important;
  width: 100%;
  background-color: #f9f9f9;
}
